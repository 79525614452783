body,
.root {
  overflow-x: hidden;
  max-width: 100vw;
  background-color: aqua;
}

#menu {
  display: none;
}
